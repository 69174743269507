export const STATUS_NOVEL_PENDING_REVIEW = 'PENDING_REVIEW'
export const STATUS_NOVEL_PROCESSING = 'PROCESSING'
export const STATUS_NOVEL_FAILED_PROCESSING = 'FAILED_PROCESSING'
export const STATUS_NOVEL_PUBLISHED = 'PUBLISHED'

export function isNovelInProcessCrawl(status: string) {
  return [
    STATUS_NOVEL_PROCESSING,
  ].includes(status)
}
